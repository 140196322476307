.card {
  overflow: visible;
}

.cardContent {
  padding-bottom: var(--spaceM);
}

.cardTitle {
  margin-bottom: var(--spaceM);
}

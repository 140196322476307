.stepTracker {
  background: transparent;
}

.stepTracker > button {
  font-weight: 300;
  cursor: auto;
}

.stepTracker > button:hover {
  font-weight: 300;
  background: transparent;
}

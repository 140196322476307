.header {
  margin-bottom: var(--spaceM);
}

.familySelect {
  margin-bottom: var(--spaceM);
}

.buttonWrapper {
  flex: 0 0 auto;
}

.list {
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;
}

.listItem {
  margin-bottom: var(--spaceM);
}

.block {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
}

.icons {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}

.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

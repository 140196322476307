.list {
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;
}

.listItem {
  margin-bottom: var(--spaceS);
}

.listItem:last-child {
  margin-bottom: 0;
}

.card {
  padding-bottom: var(--spaceM);
}

.list {
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;
}

.listItem {
  margin-bottom: var(--spaceS);
}

.listItem:last-child {
  margin-bottom: 0;
}

.listItem :global .mmp-custom-collapsible-header {
  background: var(--primaryB100);
}
.listItem :global .mmp-custom-collapsible-content {
  border: 1px solid var(--contrastColor20);
  border-top: 0;
}

.footer {
  padding-top: var(--spaceS);
}

.mainSection {
  .emptyState {
    height: 100%;
    width: 50%;
    background: var(--primaryB100);
    background-color: var(--primaryB100);

    @media only screen and (max-width: 1026px) {
      width: 100%;
    }
  }

  .imageContainer {
    height: 100%;
    width: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media only screen and (max-width: 1026px) {
      display: none;
    }
  }

  .logo {
    margin-bottom: var(--spaceXXL);
  }

  .mainStack {
    max-width: 416px;
  }

  .emptyStateHeader {
    margin-bottom: var(--spaceM);
  }

  .emptyStateSubHeader {
    margin-bottom: var(--spaceXL);
  }

  .linkStack {
    margin-bottom: var(--spaceXL);
  }

  .buttonGroup {
    display: flex;
    gap: var(--spaceM);
  }
}

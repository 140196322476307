.wrapper {
  background: var(--contrastColor10);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--contrastColor10);
  padding: var(--spaceXS) var(--spaceS);
  cursor: pointer;
}

.header:hover {
  background: var(--contrastColor20);
}

.headerLeft {
  display: flex;
}

.headerTitle {
  margin-left: var(--spaceS);
}

.headerRight {
  display: flex;
  align-items: center;
}

.content {
  border-top: 1px solid var(--contrastColor20);
  padding: var(--spaceM);
}

@import '@atlas-design-system/core/dist/styles/main.css';
@import '@atlas-design-system/tokens/dist/tokens.css';

.mmp-ui-header-logout-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}

.mmp-ui-page-header {
  margin-bottom: var(--spaceXL);
}

.mmp-ui-page-container {
  padding-top: var(--spaceXXL);
  padding-bottom: var(--spaceXXL);
  min-height: calc(100vh - 353px);
}

.mmp-ui-navigation-tabs button[data-disabled='true'] {
  opacity: 0.7;
  cursor: not-allowed !important;
}

.atls-header .logo .atls-logo {
  height: 32px;
  width: 391px;
}

.atls-card {
  overflow: unset;
}
.atls-select__menu-100 .atls-select-next__menu {
  min-width: 100px;
}
.atls-select__menu-300 .atls-select-next__menu {
  min-width: 300px;
}

.atls-select__menu-400 .atls-select-next__menu {
  min-width: 400px;
  max-height: 100vh;
}
.atls-select__menu-400 .atls-select-next__menu-list {
  max-height: 360px;
}

.atls-select-height-360 .atls-select-next__menu {
  max-height: 100vh;
}
.atls-select-height-360 .atls-select-next__menu-list {
  max-height: 360px;
}

.atls-select-height-200 .atls-select-next__menu-list {
  max-height: 200px;
}

.mmp-ui-permission-select.atls-select-next .atls-select-next__menu {
  width: 200px;
}
.mmp-form-card {
  padding-bottom: 16px;
}

.mmp-ancillary-collapsible-action-group {
  position: absolute;
  display: flex;
  align-items: center;
  top: -2px;
  right: var(--spaceL);
}

#promote-family-modal,
#family-confirm-modal {
  height: 260px;
  width: 340px;
}

#chargeable-modal {
  height: 360px;
  width: 760px;
}

#mandatory-modal {
  height: 400px;
  width: 600px;
}

#ancillary-confirm-dialog {
  min-height: 230px;
  height: auto;
  width: 460px;
}

#view-filing-modal {
  min-height: 360px;
  height: auto;
}

#ancillary-confirm-dialog .atls-formControl {
  flex-direction: row;
  justify-content: space-between;
  margin: var(--spaceM) 0 0;
}

#ancillary-confirm-dialog .dialogActions {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

#ancillary-confirm-dialog .atls-buttonGroup {
  margin: 0;
  padding: 0;
  align-self: flex-end;
}

#promote-family-modal .content {
  overflow-y: unset;
}

.atls-picker-panel {
  position: relative;
  z-index: 2;
}

.mmp-ui-form-control-required .labelContent label:after {
  content: ' *';
  color: var(--statusErrorBase);
}

.atls-toast {
  max-width: 460px !important;
  width: 100% !important;
}

.atls-toast .content {
  width: auto;
}

.link-button {
  font-weight: 400 !important;
  padding: 0 !important;
  color: var(--accentA600) !important;
}

.link-button.disabled {
  color: var(--contrastColor70) !important;
  cursor: default;
}

.link-button:hover,
.link-button:active,
.link-button:disabled {
  background: transparent !important;
  border-color: transparent !important;
}
.atls-card.dashboard {
  height: 322.5px;
}

.error .atls-select-next__value-container input {
  padding: 0 !important;
}
/* Below 4 blocks are used to alter the size of the location code select label to intercept click events.
   This fixes a defect where formik validation sytles would prevent focus change to the location-code-select component on first click. */
.location-code-label .labelContent {
  position: relative;
  top: 0;
  left: 0;
  margin-top: 19.5px;
}
.location-code-label label {
  position: absolute;
  top: -19.5px;
  z-index: 1;
  height: 55px;
  width: 100%;
}

.atls-select-next__clear-indicator {
  z-index: 2;
}

/* End location-code-select label styles */
.mmp-ui-spinner {
  position: relative;
}

.atls-spinner.pseudo-city-codes {
  position: relative;
}

.atls-select-next__menu {
  z-index: 2 !important;
}

form.atls-form.price-range-form {
  padding-bottom: 100px;
}

form.atls-form.price-range-form .atls-formControl {
  margin-bottom: 0;
}

form.atls-form.price-range-form .price-range-form-row {
  margin-bottom: var(--spaceM);
}

form.atls-form.price-range-form .atls-select__menu {
  z-index: 1300;
}

form.atls-form.price-range-form .errorMessage {
  display: none;
}

form.atls-form.price-range-form .atls-select-next__menu {
  z-index: 20000 !important;
}

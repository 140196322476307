.list {
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;
}

.listItem {
  margin-bottom: var(--spaceS);
}

.listItem:last-child {
  margin-bottom: 0;
}

.footer {
  padding-top: var(--spaceS);
}

.modalBtnsGroup {
  display: flex;
  column-gap: var(--spaceS);
}

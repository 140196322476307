.pseudoCityCodes {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-bottom: 100px;
}
.pseudoCityCodesContent {
  display: flex;
  flex-wrap: wrap;
}
.geographyList {
  margin-bottom: 20px;
}

.carrierFormControl {
  margin-bottom: var(--spaceS);
}

.radioButtons {
  margin-top: 2%;
}

.formHeader {
  margin-top: var(--spaceM);
  margin-bottom: var(--spaceM);
}

.addButton {
  margin-top: 24px;
  display: flex;
  justify-content: right;
}

.emailList {
  padding-bottom: 8px;
}

.emailHeader {
  margin-bottom: 0 !important;
}

.emailActionButtons {
  margin-top: 2.4%;
}

.formSubmitButtonGroup {
  margin-top: 2%;
}

.formRowError {
  margin-top: -28px;
}

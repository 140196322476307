.list {
  padding-left: var(--spaceL);
}

.statusIcon {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  padding: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.wrapper {
  position: relative;
}
